import { Organization } from '@mp/kernel/organizations/domain';

export function* filterOrganizationsBySearchTerm(
  organizations: Iterable<Organization>,
  searchTerm: string,
): Iterable<Organization> {
  if (!searchTerm) {
    yield* organizations;
  }

  searchTerm = searchTerm.toLowerCase();

  for (const o of organizations) {
    if (o.name.toLowerCase().includes(searchTerm)) {
      yield o;
    }
  }
}
